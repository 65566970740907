define("discourse/plugins/discourse-mycad-slider/initializers/discourse-mycad-slider", ["exports", "discourse/lib/load-script", "discourse/lib/plugin-api"], function (_exports, _loadScript, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'discourse-mycad-slider',
    initialize(container) {
      (0, _pluginApi.withPluginApi)('0.12.1', api => {
        let left_icon = '<svg xmlns="http://www.w3.org/2000/svg" width="22.414" height="42.828" viewBox="0 0 22.414 42.828"><path id="chevron-left" d="M29,46,9,26,29,6" transform="translate(-8 -4.586)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/></svg>';
        let right_icon = '<svg xmlns="http://www.w3.org/2000/svg" width="22.414" height="42.828" viewBox="0 0 22.414 42.828"><path id="chevron-right" d="M29,46,9,26,29,6" transform="translate(30.414 47.414) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/></svg>';
        let siteSettings = container.lookup('site-settings:main');
        $(document).ajaxSend(function (event, xhr, settings) {
          settings.xhrFields = {
            withCredentials: true
          };
        });
        (0, _loadScript.default)('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js').then(() => {
          init_mycad_slider();
        });
        function init_mycad_slider() {
          let mycadSlideAPI = siteSettings.mycad_host + '/' + I18n.locale + "/discourse/slideshow";
          $.getJSON(mycadSlideAPI, function (slide_data) {
            $.each(slide_data, function (i, item) {
              $("#mycad-slider-images").hide().append("<div id='slide-" + i + "'></div>");
              $("<a></a>").attr("href", item.image_Link).attr("title", item.title).attr('target', '_blank').appendTo("#mycad-slider-images div#slide-" + i);
              $("<img>").attr("src", item.image).appendTo("#mycad-slider-images div#slide-" + i + " a");
            });
            setTimeout(() => {
              $('.js-banner-slick').slick({
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                dots: true,
                adaptiveHeight: true,
                autoplay: true,
                autoplaySpeed: 5000,
                prevArrow: '<span class="slick-prev">' + left_icon + '</span>',
                nextArrow: '<span class="slick-next">' + right_icon + '</span>'
              });
              $('#mycad-slider-images').show();
              $('#mycad-slider-loading').hide();
            }, 600);
            if (slide_data == null) {
              $('.mycad-slider').hide();
            }
          }).fail(function () {
            console.log("Error while getting mycad slider!");
            $('.mycad-slider').hide();
          });
        }
      });
    }
  };
});